import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AboutUsPage = () => (
  <Layout>
    <Seo
      title="About Us"
      description="Agoro Carbon Alliance is decarbonizing farming on a global scale by generating reliable carbon credits and certified climate-smart crops."
    />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-home.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a hand full of dirt"
        />
      </div>
      <div className="headline">
        <h1>About Us</h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          The Agoro Carbon Alliance is decarbonizing the food value chain to build a better agricultural future.
        </p>
        <p>
          We&rsquo;re the only carbon cropping initiative built on an active network of global partnerships, knowledge sharing, and transparency that enables partners to adopt climate-positive practices that add new revenue streams to their operations.
        </p>
        <p>
        With the backing of Yara&rsquo;s global footprint and partnerships in the United States, Europe, South America, and Asia, we&rsquo;re making these opportunities accessible to farmers of various backgrounds.
        </p>
        <p>
          Together, we can meet the growing need for decarbonization in the private sector by providing solutions that make our farms more profitable and sustainable.
        </p>
        <ul role="list" className="profiles flow">
          <li>
            <StaticImage
              className="about-profile-img"
              src="../images/about-iule.jpg"
              width={200}
              height={300}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Iule Arruda head shot"
            />
            <p>
              <strong className="faux-title">Brazil</strong>
              <br></br>
              Iule Arruda
              <br />
              Office: <a href="tel:+551130962500">+551130962500</a>
              <br />
              Email:{" "}
              <a href="mailto:iule.arruda@yara.com">iule.arruda@yara.com</a>
            </p>
          </li>
          <li>
            <StaticImage
              className="about-profile-img"
              src="../images/about-mats.jpg"
              width={200}
              height={300}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Mats Rosenberg head shot"
            />
            <p>
              <strong className="faux-title">Europe</strong>
              <br></br>
              Mats Rosenberg
              <br />
              Phone: <a href="tel:+41782162451">+41 78 2162451</a>
              <br />
              Email:{" "}
              <a href="mailto:mats.rosenberg@agorocarbon.com">
                mats.rosenberg@agorocarbon.com
              </a>
            </p>
          </li>
          <li>
            <StaticImage
              className="about-profile-img"
              src="../images/about-anastasia.jpg"
              width={200}
              height={300}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Anastasia Pavlovic head shot"
            />
            <p>
              <strong className="faux-title">US</strong>
              <br></br>
              Anastasia Pavlovic
              <br />
              Mobile: <a href="tel:+18138166387">+1.813.816.6387</a>
              <br />
              Email:{" "}
              <a href="mailto:anastasia.pavlovic@yara.com">
                anastasia.pavlovic@yara.com
              </a>
            </p>
          </li>
          <li>
            <StaticImage
              className="about-profile-img"
              src="../images/about-prithviraj.jpg"
              width={200}
              height={300}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Prithviraj Sen Sharma head shot"
            />
            <p>
              <strong className="faux-title">India</strong>
              <br></br>
              Prithviraj Sen Sharma
              <br />
              Mobile: <a href="tel:+917899020749">+917899020749</a>
              <br />
              Email:{" "}
              <a href="mailto:prithviraj.sensharma@yara.com">
                prithviraj.sensharma@yara.com
              </a>
            </p>
          </li>
        </ul>
      </div>
      <div className="floating-col flow">
        <h3>Introducing the Agoro Carbon Alliance</h3>
        <div className="embed-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ISjgIdM-F3k"
            title="YouTube video player: Introducing the Agoro Carbon Alliance            "
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </article>
  </Layout>
);

export default AboutUsPage;
